.booklist,
.book_details {
  padding: 3rem 0;
  background-color: #f8f9fa;
}

.booklist_content {
  gap: 3rem;
}

.book_item {
  padding: 3rem 2rem;
  border-radius: 4px;
  background-color: var(--white-color);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px 0px;
  transition: var(--transition);
}

.book_item:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.book_item_img img {
  max-width: 180px;
  margin-right: auto;
  margin-left: auto;
}
.book_item_info {
  margin-top: 2rem;
}

.book_item_info_item.title {
  line-height: 1.4;
  margin-bottom: 0.8rem;
}

.book_item_info_item.author {
  line-height: 1.4;
  margin-bottom: 4px;
}

.book_item_info_item:not(.book_item_info_item.title) span:last-child {
  opacity: 0.8;
}

.book_item_info_item.publish_year {
  opacity: 0.6;
  font-style: italic;
  font-size: 14px;
}


@media screen and (min-width:600px) { 
    .booklist_content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width:992px) { 
    .booklist_content {
        grid-template-columns: repeat(3, 1fr);
    }
    .book_item_img img {
        max-width: 150px;
    }
}
@media screen and (min-width:992px) { 
    .booklist_content {
        grid-template-columns: repeat(4, 1fr);
    }
    .book_item_img img {
        max-width: 12   0px;
    }
}

