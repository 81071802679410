.search_form {
  width: 100%;
  max-width: 600px;
}
.search_form_elem {
  padding: 1.4rem 2.8rem;
  border-radius: 3.8rem;
}

.search_form_elem .form_control {
  font-size: 2rem;
  padding: 0.6rem;
}

.search_form_elem .form_control ::placeholder {
  opacity: 0.9;
}
