.book_details {
  padding: 6rem 0;
}
.back_btn {
  margin-left: 20px;
  margin-bottom: 2.6rem;
  transition: var(--transition);
}

.back_btn:hover {
  color: var(--purple-color);
}

.back_btn span {
  margin-left: 1rem;
}

.book_details_content {
  gap: 4rem;
}
.book_details_img {
  max-height: 600px;
  overflow: hidden;
}
.book_details_img img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book_details_item {
  margin-bottom: 1.4rem;
}

.book_details_item.description {
  opacity: 0.8;
}

.book_details_info {
  overflow-y: scroll;
  max-height: 600px;
  padding: 1.4rem;
}

.book_details_info::-webkit-scrollbar {
  width: 8px;
}

.book_details_info::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.book_details_info::-webkit-scrollbar-thumb {
  background-color: var(--purple-color);
  outline: -1px solid slategray;
  border-radius: 8px;
}

@media screen and (min-width: 760px) {
    .book_details_content{
        grid-template-columns: 40% 60%;
        gap: 0;
    }
    .book_details_img{
        padding: 0 6rem 0 2rem;

    }
    .book_details_img img {
        max-width: 100%;
    }
    
}
