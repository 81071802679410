@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --purple-color: #8d27ae;
  --pink-color: #d9176d;
  --grey-color: #f3f3f3;
  --white-color: #fff;
  --black-color: #010101;
  --light-black-color: #717171;
  --transition: all 300ms ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: "poppins", sans-serif;
  color: var(--black-color);
}

/* resets */

ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: var(--black-color);
}

button {
  outline: 0;
  border: none;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
}
img {
  width: 100%;
  display: block;
}
input {
  border: none;
  outline: 0;
  font-family: inherit;
  font-size: 1.6rem;
}
.resize-animation-stopper {
  animation: none !important;
  transition: none !important;
}

/* backgrounds */

.bg_pink {
  background-color: var(--pink-color);
}
.bg_purple {
  background-color: var(--purple-color);
}
.bg_white {
  background-color: var(--white-color);
}

/* text */

.text_black {
  color: var(--black-color);
}
.text_white {
  color: var(--white-color);
}
.text_purple {
  color: var(--purple-color);
}
.text_light_black {
  color: var(--light-black-color);
}
.text_uppercase {
  text-transform: uppercase;
}
.text_center {
  text-align: center;
}
.text_capitalize {
  text-transform: capitalize;
}
.text_italic {
  font-style: italic;
}

/* font weights */

.fw_3 {
  font-weight: 300;
}
.fw_4 {
  font-weight: 400;
}
.fw_5 {
  font-weight: 500;
}
.fw_6 {
  font-weight: 600;
}
.fw_7 {
  font-weight: 700;
}
.fw_8 {
  font-weight: 800;
}
.fw_9 {
  font-weight: 900;
}

/* font size */

.fs_15 {
  font-size: 1.5rem;
}
.fs_16 {
  font-size: 1.6rem;
}
.fs_17 {
  font-size: 1.7rem;
}
.fs_18 {
  font-size: 1.8rem;
}
.fs_20 {
  font-size: 2rem;
}
.fs_22 {
  font-size: 2.2rem;
}
.fs_24 {
  font-size: 2.4rem;
}
.fs_26 {
  font-size: 2.6rem;
}

/* letter spacing */

.ls_1 {
  letter-spacing: 1px;
}
.ls_2 {
  letter-spacing: 2px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex_column {
  flex-direction: column;
}

.flex_sb {
  justify-content: space-between;
}
.flex_c {
  justify-content: center;
}

.grid {
  display: grid;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* section title */

.section_title h2 {
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 0 24px 0;
}


.footer {
  text-align: center;
}