.header_content {
  min-height: 75vh;
  background: linear-gradient(rgba(141, 39, 174, 0.3), rgba(141, 39, 174, 0.5)),
    url("../../images/pic.jpg") center/cover no-repeat;
    flex-direction: column;
}

.header_title{
    font-size: 4.2rem;
}

.header_text{
    margin-top: -1.8rem;
    margin-bottom: 2.0rem;
    opacity: 0.8;
    max-width: 770px;
}
